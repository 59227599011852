import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getOr } from 'lodash/fp';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import { Cart } from '../../../../ecomm/types/cart.types';
import { FulfillmentTimes } from '../../../../ecomm/types/fulfillment-times.types';
import {
  MenuCategory,
  MenuItem,
  StoreDetails,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';

import { CartFulfillmentTimeComponent } from '../cart-fulfillment-time/cart-fulfillment-time.component';
import { TipSelectionPipe } from '../../../common/pipes/tip-selection.pipe';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  SCRIPT_LOADER,
  ScriptLoader
} from '../../../../ecomm/providers/script-loader/script-loader.provider';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { getLaterTime } from '../../../../ecomm/utils/moment';
import { ReCaptchaService } from '../../../../ecomm/utils/recaptcha/recaptcha.service';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { getCategoryFromItemId } from '../../../../ecomm/workflows/store-info/store-info.utilities';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  BagPageService,
  PartialOutageModalComponent
} from '../../../common';
import { FutureFullDateChangedEvent } from '../../../common/types/cart.types';
import {
  ActiveOfferFeature,
  ActiveOfferFeatureState
} from '../../../../ecomm/store/features/active-offer';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import {
  LocationDetailsFeature,
  LocationDetailsFeatureState
} from '../../../../ecomm/store/features/location-details';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreInfoWorkflowService } from '../../../../../public-api';

type MenuItemWithSlug = {
  categorySlug: string;
  productSlug: string;
  itemSlug?: string;
  item: MenuItem;
};

@Component({
  selector: 'wri-bag',
  templateUrl: './connected-bag.component.html',
  styleUrls: ['./connected-bag.component.scss']
})
export class ConnectedBagComponent implements OnInit, OnDestroy, AfterViewInit {
  private static readonly MIN_MOBILE_WIDTH = 992;

  @ViewChild(CartFulfillmentTimeComponent)
  childComponent!: CartFulfillmentTimeComponent;

  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  public cart: Cart | null = null;
  public storeInfoState: StoreInfoFeatureState | null = null;
  public locationDetailsState: LocationDetailsFeatureState | null = null;
  public storeInfo$ = new BehaviorSubject<
    StoreInfo | { categories: MenuCategory[]; storeDetails: StoreDetails }
  >({
    categories: [],
    storeDetails: {} as StoreDetails
  });
  public selectTimeErrorType$ = new BehaviorSubject('');
  public cartState: CartFeatureState | null = null;
  public activeOfferState: ActiveOfferFeatureState | null = null;
  public isLoading = true;
  public fulfillmentTime: FulfillmentTimes | null = null;
  public numItems = 0;
  public fulfillmentLaterFlowStateOpen: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public laterTime: string | undefined = '';
  public showPhoneNumber = false;
  private isFinalizeCartCalled = false;
  itemsForMenuAdditionalRecommendation$: Observable<MenuItemWithSlug[]> =
    this.storeInfo$?.pipe(
      map((storeInfo) => {
        const retVal: MenuItemWithSlug[] = [];

        storeInfo.categories.forEach((category) => {
          category.products.forEach((product) => {
            if (
              product.item !== null &&
              product.item?.metadata &&
              product.item?.metadata?.['cart-product-carousel'] === 'true'
            )
              retVal.push({
                categorySlug: category.slug,
                productSlug: product.slug,
                item: product.item
              });

            if (product.itemGroup !== null) {
              product.itemGroup?.items.forEach((item) => {
                if (
                  (item as MenuItem | undefined)?.metadata &&
                  (item as MenuItem | undefined)?.metadata?.[
                    'cart-product-carousel'
                  ] === 'true'
                )
                  retVal.push({
                    categorySlug: category.slug,
                    productSlug: product.slug,
                    itemSlug: item.slug,
                    item: item
                  });
              });
            }
          });
        });

        // get unique items based on item name
        return [
          ...new Map(retVal.map((el) => [el.item?.['name'] ?? '', el])).values()
        ];
      })
    );
  private fireGaEvent = true;
  private subscription = new Subscription();

  public regionalConfig$ = this.store
    .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
    .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: BagPageService,
    private cartService: CartWorkflowService,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    @Inject(SCRIPT_LOADER)
    private scriptLoaderProvider: ScriptLoader,
    private reCaptchaService: ReCaptchaService,
    private analyticsService: AnalyticsService,
    @Inject(WINDOW) private _window: Window,
    private modalService: NgbModal
  ) {}

  get isMobile() {
    return this._window.innerWidth < ConnectedBagComponent.MIN_MOBILE_WIDTH;
  }

  ngOnInit(): void {
    addSpaceToBodyWithStickyFooter('bag-page');
    //load grecaptcha in head tag
    this.scriptLoaderProvider(this.reCaptchaService.getRecaptchaURL());
    this.setNumOfItemsInCart();
    this.subscription.add(
      this.route.data
        .pipe(
          filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
          tap(() => (this.isLoading = true)),
          switchMap((data) =>
            this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT])
          )
        )
        .subscribe(() => {
          this.isLoading = this.isFinalizeCartCalled;
        })
    );
    this.subscribeToLocationMenuState();
    this.subscribeToCartState();
    this.ftForLater();
    this.subscribeToActiveOfferState();
    this.subscribeToLocationDetailsState();
    this.subscribeToRegionalConfigState();
  }

  ngAfterViewInit() {
    if (this.cart?.fulfillment?.status === 'invalid') {
      this.selectTimeErrorType$.next(
        this.cart?.fulfillment?.statusReason ?? 'Invalid Date'
      );
    } else {
      this.selectTimeErrorType$.next('');
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  openResetCartModal() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  public closeModal() {
    this.modalService.dismissAll();
  }

  public resetCartState() {
    this.cartService.resetCartState();
    this.storeInfoWorkflowService.resetStoreInfoState();
    this.modalService.dismissAll();
    this.navigateToOrder();
  }

  private navigateToOrder() {
    this.router.navigate(['/order/']);
  }

  public isTippingAvailable(): boolean {
    return new TipSelectionPipe().transform(
      this.cart,
      this.storeInfo$.value?.storeDetails ?? null
    );
  }

  public goToMenu = () => {
    const url = `/location/${this.storeInfo$.value?.storeDetails.slug}/menu`;
    this.router.navigate([url]);
    this.analyticsService.logGaEvent({
      event: 'add_more_items'
    });
  };

  public isCartEmpty(): boolean {
    if (this.cart === null) {
      return true;
    }
    if (this.cart?.items && this.cart.items.length === 0) {
      return true;
    }
    return false;
  }

  public scrollToSelectTime() {
    const el = document.getElementById('select-time-to-order');
    const headerOffset = 90;
    const elementPosition = el?.getBoundingClientRect().top || 0;
    const offsetPosition =
      elementPosition + this._window.scrollY - headerOffset;
    this._window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  }

  public localValidationForTimeSelection() {
    const { asap } = this.cart || {};
    const { isStoreAcceptingAsapOrders } =
      this.storeInfoState?.storeInfo?.fulfillmentTimes || {};
    if (
      (asap && !isStoreAcceptingAsapOrders) ||
      this.fulfillmentLaterFlowStateOpen.value
    ) {
      this.selectTimeErrorType$.next('Please select a later day and time.');
      return true;
    }
    return false;
  }

  public async postValidationForTimeSelection() {
    const { status, statusReason } = this.cart?.fulfillment || {};
    if (status === 'invalid') {
      this.selectTimeErrorType$.next(statusReason ?? 'Invalid Date');
      this.scrollToSelectTime();
    } else {
      this.selectTimeErrorType$.next('');
    }
  }

  public async goToCheckout() {
    const hasErrors = this.localValidationForTimeSelection();
    if (hasErrors) {
      this.scrollToSelectTime();
    } else {
      this.isFinalizeCartCalled = true;
      await this.finalizeCart();
      await this.postValidationForTimeSelection();
    }
  }

  isCartInAsapMode(): boolean {
    return <boolean>this.cart?.asap;
  }

  public async onFutureDateSave({
    futureDate,
    asap
  }: FutureFullDateChangedEvent) {
    //API call
    if (this.cart) {
      const response = await this.cartService.saveFutureDate(
        this.cart.cartId,
        asap,
        futureDate
      );
      //passing API data to child component
      this.childComponent.onApiResponse(asap, response);

      //check validation on selection of new time
      await this.postValidationForTimeSelection();
    }
  }

  public selectedDateTimeValid(isValid: boolean) {
    if (!isValid) {
      this.selectTimeErrorType$.next('Please select a later day and time.');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
        this.cart = state.cart;
        this.setNumOfItemsInCart();
        this.ftForLater();
        if (this.fireGaEvent && this.cart?.items.length !== undefined) {
          this.logViewCartGAEvent();
          this.fireGaEvent = false;
        }
      })
    );
  }

  private subscribeToActiveOfferState(): void {
    const activeOfferState$ = this.store
      .select(ActiveOfferFeature.selectActiveOfferState)
      .pipe(filter<ActiveOfferFeatureState>(Boolean));

    this.subscription.add(
      activeOfferState$.subscribe((state) => {
        this.activeOfferState = state;
      })
    );
  }

  private logViewCartGAEvent(): void {
    this.analyticsService.logGaEvent({
      event: 'view_cart',
      ecommerce: {
        items:
          this?.cart?.items.map((item) => ({
            item_category:
              getCategoryFromItemId(
                item.productId,
                this.storeInfoState?.storeInfo?.categories
              ) || '',
            item_id: item.productId || '',
            item_name: item.productName || '',
            price: item.itemSubtotal,
            quantity: item.quantity,
            value: item.unitPrice || 0
          })) || []
      },
      value: this.cart?.total || 0,
      currency: 'USD',
      order_method: (this.cart?.handoffMode as HandoffMode) || 'carryout', //'carryout'|'curbside'|'delivery'|'dinein',
      store_name: this.storeInfoState?.storeInfo?.storeDetails.name || ''
    });
  }

  private setNumOfItemsInCart(): void {
    // set up the number of items in cart
    this.numItems = 0;
    if (this.cart?.items && Array.isArray(this.cart.items)) {
      this.cart.items.forEach((cartItem) => {
        this.numItems += cartItem.quantity;
      });
    }
  }

  private subscribeToLocationMenuState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfoState = state;
        this.storeInfo$.next(
          state.storeInfo ?? {
            categories: [],
            storeDetails: {} as StoreDetails
          }
        );
        this.fulfillmentTime = state.storeInfo?.fulfillmentTimes ?? null;
        this.ftForLater();
      })
    );
  }

  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.locationDetailsState = state;
      })
    );
  }

  getOverrideReasonDescription(): string {
    const { locationDetails } = this.locationDetailsState ?? {};
    const {
      unavailableCarryoutOverrideHoursActive,
      unavailableOverrideReasonDescription
    } = locationDetails?.location ?? {};

    return unavailableCarryoutOverrideHoursActive &&
      unavailableOverrideReasonDescription
      ? unavailableOverrideReasonDescription
      : '';
  }

  private isDonationCategoryAvailableInCart(): boolean {
    return this.cart?.fees.some((a) => a.category === 'donation') || false;
  }

  private async finalizeCart() {
    const cartFinalizeResponse = await this.cartService.finalizeCart(
      this.cart?.cartId || '',
      this.isDonationCategoryAvailableInCart()
    );
    if (cartFinalizeResponse && cartFinalizeResponse.status === 'finalized') {
      this.router.navigate(['/order/checkout']);
    }
    this.isFinalizeCartCalled = false;
    this.isLoading = false;
  }

  private ftForLater() {
    const timeZone = getOr(
      '',
      ['fulfillmentTimes', 'locationTimeZone'],
      this.storeInfo$.value
    );
    this.laterTime = !this.isCartInAsapMode()
      ? getLaterTime(this.cart?.fulfillmentTime, timeZone)
      : '';
  }
}
